// @ts-nocheck

import { createStore } from 'vuex'


interface Notification {
  id: number;
  message: string;
  // Add other properties as needed
}

interface State {
  UserProfile: Record<string, any>;
  Auth_Tokens: Record<string, any>;
  password: string | null;
  unreadNotifications: Notification[];
}



export default createStore({
  state: {
    unreadNotifications: [] as Notification[]
  },
  getters: {
  },
  mutations: {
    updateNotifications(state, newNotifications) {
      state.unreadNotifications = [...state.unreadNotifications, ...newNotifications]; // Adds new notifications to the end of the array
    }
  },
  actions: {
    updateNotifications({ commit }, newNotifications) {
      commit('updateNotifications', newNotifications); // Action to update notifications
    },
  },
  modules: {
  }
})
