import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footer"
}

import { useRouter } from 'vue-router';
import { computed } from 'vue';
import api from '@/services/api';
import { useAuthStore } from '@/store/authStore';
import { useLikeStore } from '@/store/likeStore';
import { useChallengeStore } from '@/store/challengeStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollItemFooter',
  props: {
  challenge: Object, 
},
  setup(__props) {

const authStore = useAuthStore();
const likeStore = useLikeStore();
const challengeStore = useChallengeStore();
const accessToken = authStore.getAccessToken() as string;

const props = __props;

const isLiked = computed(() => {
    if (!props.challenge) return false;
    return likeStore.isLiked(`CHALLENGE#${props.challenge.challenge_id}`)
});

const challengeCount = computed(() => {
  if (!props.challenge) return 0;
    const challenge = challengeStore.getChallenge(props.challenge.PK);
    return challenge?.like_count || 0;
});

async function likeChallenge() {
  if (props.challenge) {
    const storedChallenge = challengeStore.getChallenge(props.challenge.PK);
    if (!storedChallenge) return;

    const currentLikeCount = Number(storedChallenge.like_count ?? 0);

    if (likeStore.isLiked(`CHALLENGE#${props.challenge.challenge_id}`)) {
      likeStore.removeLike(`CHALLENGE#${props.challenge.challenge_id}`);
      challengeStore.updateChallenge(props.challenge.PK, { like_count: currentLikeCount - 1 });
      await api.unlike(accessToken, "CHALLENGE", props.challenge.challenge_id)
    }
    else {
      likeStore.addUserLikes([`CHALLENGE#${props.challenge.challenge_id}`]);
      challengeStore.updateChallenge(props.challenge.PK, { like_count: currentLikeCount + 1 });
      await api.like(accessToken, "CHALLENGE", props.challenge.challenge_id)
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (__props.challenge)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          class: "nav-button",
          onClick: likeChallenge
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              color: isLiked.value ? '#FF5C39' : 'default',
              size: "24",
              class: "mr-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("mdi-thumb-up-outline")
              ])),
              _: 1
            }, 8, ["color"]),
            _createTextVNode(" " + _toDisplayString(challengeCount.value), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "nav-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ path: '/challenge-details', query: { challenge_id: __props.challenge.challenge_id, challenge_user_id: __props.challenge.user_id } })))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              size: "24",
              class: "mr-2"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("mdi-account-multiple-outline")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(__props.challenge.response_count), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "nav-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ path: '/challenge-details', query: { challenge_id: __props.challenge.challenge_id, challenge_user_id: __props.challenge.user_id } })))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              size: "24",
              class: "mr-2"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("mdi-chart-bar-stacked")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(__props.challenge.view_count), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "nav-button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ path: '/challenge-details', query: { challenge_id: __props.challenge.challenge_id, challenge_user_id: __props.challenge.user_id } })))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              size: "24",
              class: "mr-2"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("mdi-comment-text-outline")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(__props.challenge.comment_count), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})