import { defineStore } from 'pinia';
import api from '@/services/api';
import { NotificationService, LastEvaluatedKey } from '@/interfaces';
import { useAuthStore } from './authStore';

interface NoticeStoreState {
    unreadCount: number;
    notifications: NotificationService[];
    lastEvaluatedKey: LastEvaluatedKey | null;
}


export const useNoticeStore = defineStore('noticeStore', {
    state: (): NoticeStoreState => ({
        unreadCount: 0,
        notifications: [],
        lastEvaluatedKey: null,
    }),
    actions: {
        async fetchNotifications(
            lastEvaluatedKey: LastEvaluatedKey | null = null,
            limit = 25
        ): Promise<{ items: NotificationService[]; lastEvaluatedKey: LastEvaluatedKey | null } | void> {
            const accessToken = useAuthStore().getAccessToken();
            if (!accessToken) {
                console.error('No access token available.');
                return;
            }
            const response = await api.getNotifications(accessToken, limit, lastEvaluatedKey);
            return {
                items: response.data.notifications,
                lastEvaluatedKey: response.data.lastEvaluatedKey,
            };
        },
        getUnread(): NotificationService[] {
            return this.notifications.filter((item) => item.SK.startsWith('#unread'));
        },
        getRead(): NotificationService[] {
            return this.notifications.filter((item) => item.SK.startsWith('#read'));
        },
    },
});
