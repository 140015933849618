// store/responseStore.ts
import { defineStore } from 'pinia';
import { Response } from '@/models/Response';
import ResponseService from '@/services/ResponseService';
import { cacheWithTTL } from '@/utils/cacheWithTTTL';
import hash from 'object-hash';
import api from '@/services/api';

interface ResponseStoreState {
    responses: { [PK: string]: Response };
}

export const useResponseStore = defineStore('responseStore', {
    state: (): ResponseStoreState => ({
        responses: {},
    }),
    persist: true,

    actions: {
        // Add multiple challenges to the store by PK
        add(response_list: Response[]) {
            console.log('response_list', response_list);
            response_list.forEach((response) => {
                if (response.PK) {
                    this.responses[response.PK] = response;
                }
            });
        },
        // Get a single challenge by its PK
        get(PK: string): Response | undefined {
            return this.responses[PK];
        },

        // Remove a challenge by its PK
        remove(PK: string) {
            if (this.responses[PK]) {
                delete this.responses[PK];
            }
        },

        // Clear the entire challenge cache
        clear() {
            this.responses = {};
        },

        // Update specific properties of a challenge (e.g., like or comment counts)
        update(PK: string, updates: Partial<Response>) {
            if (this.responses[PK]) {
                this.responses[PK] = { ...this.responses[PK], ...updates };
            }
        },
    }
});
