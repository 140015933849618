<template>
    <transition name="fade" @after-enter="startFadeOut">
      <div class="info-box" v-if="internalMessage">
        {{ infoMessage }}
      </div>
    </transition>
  </template>
  
  <script setup>
  import { ref, watch, onUnmounted } from 'vue';
  import { defineProps } from 'vue';
  
  // Define props
  const props = defineProps({
    infoMessage: {
      type: String,
      default: ''
    }
  });
  
  // State
  const fadeTimeout = ref(null);
  const internalMessage = ref(props.infoMessage);
  
  // Function to handle the fade-out effect
  const startFadeOut = () => {
    // Clear any existing timeout to avoid multiple triggers
    if (fadeTimeout.value) clearTimeout(fadeTimeout.value);
  
    // Set timeout to clear message after 2 seconds
    fadeTimeout.value = setTimeout(() => {
      internalMessage.value = '';
    }, 1000);
  };
  
  // Watch for changes in the `infoMessage` prop
  watch(
    () => props.infoMessage,
    (newVal) => {
      if (newVal) {
        internalMessage.value = newVal;
        startFadeOut();
      }
    }
  );
  
  // Clear the timeout when component unmounts
  onUnmounted(() => {
    if (fadeTimeout.value) clearTimeout(fadeTimeout.value);
  });
  </script>
  
  <style scoped>
  .info-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 1.5em;
    transition: opacity 1.75s ease;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2.00s ease;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  