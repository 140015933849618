<!-- TopicSlider.vue -->
<template>
    <div class="topic-container">

        <v-slide-group
          show-arrows
          v-model="tab"
        >
            <div
              v-for="(topic, index) in topics"
              :key="topic"
              class="pr-1 pl-1"
            >
              <h3
                  :class="{'selected-topic': tab === index}"
                  class="topic-item pa-1"
                  @click="selectTopic(index, topic)"
                >
                  {{ topic }}
              </h3>
              </div>
        </v-slide-group>
    </div>
</template>
  
<script setup>
import { ref } from 'vue';

const emit = defineEmits(['topic-selected']);
const tab = ref(null);
const topics = [
  'Recents',
  'Trending',
  // 'General',
  // 'Skateboarding',
  // 'Snowboarding',
  // 'Surfing',
  // 'BMX',
  // 'Parkour',
  // 'Rock Climbing',
  // 'Breakdancing',
  // 'Freestyle Football',
  // 'Gaming',
  // 'Music Battles',
];
const selectTopic = (index, topic) => {
  tab.value = index;
  emit('topic-selected', topic);
};
selectTopic(0, 'Recents');
</script>

<style scoped>
.topic-container {
  width: 100%;
  display: flex;
  justify-content: center;

}


.topic-item {
  border-radius: 25px;
  transition: border 0.3s ease;
}

.selected-topic {
  border: 2px solid #FF5C39;
}
</style>
