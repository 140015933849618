import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';  // Ensure Material Design Icons are included
import 'vuetify/styles';  // Import Vuetify styles

const customDarkTheme = {
  dark: true,
  variables: {
    headerBackgroundColor: '#ff0000', // Red for Christmas
    bottomNavBackgroundColor: '#00ff00', // Green for Christmas
  },
  colors: {
    background: "#111111",
    primary: "#FF5C45",
    secondary: "#00C1DE",
    surface: "#121212",

    duelly: "#FF5C45",
    duelly_black: "#111111",
    duelly_aquq: "#00C1DE",

    tertiary: "#4ae24a",
    error: "#f44336",
    info: "#2196F3",
    success: "#4caf50",
    warning: "#fb8c00",
  },
};
export default createVuetify({
  theme: {
    defaultTheme: "customDarkTheme",
    themes: {
      customDarkTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',  // Material Design Icons
  },
});
