/**
 * WebSocketService is a class that manages WebSocket connections for a user.
 * It establishes a connection to a WebSocket server, handles message reception, 
 * and periodically reconnects to ensure the connection remains active.
 * 
 * Features:
 * - Automatically reconnects the WebSocket every X minutes (configurable).
 * - Clears reconnection intervals on closure to prevent unnecessary reconnect attempts.
 * - Can send messages through the WebSocket when it's open.
 * 
 * Usage:
 * - Call `connect()` to establish the WebSocket connection for a user.
 * - `startReconnection()` periodically closes and re-establishes the connection to 
 *   ensure it remains active and synced.
 * - `sendMessage(data)` allows sending a message through the WebSocket if the 
 *   connection is open.
 * - `close()` will close the WebSocket connection and stop any reconnection logic.
 * 
 * Parameters:
 * - `userId`: The ID of the user initiating the WebSocket connection.
 * - `environment`: The environment (e.g., 'dev', 'prod') where the WebSocket is deployed.
 * 
 * This service is optimized for scaling and can be used in applications where 
 * maintaining an active WebSocket connection for notifications is critical.
 */
import { EventEmitter } from 'events';

const WS_BASE_URL = process.env.VUE_APP_WS_BASE_URL;

if (!WS_BASE_URL) {
    throw new Error('WebSocket base URL is not defined.');
}
export interface WebSocketServiceOptions {
    accessToken: string;
    environment: string;
    heartbeatInterval: number;
    deviceId: string;
}

export default class WebSocketService extends EventEmitter {
    private static instance: WebSocketService | null = null;
    private accessToken: string;
    private env: string;
    private socket: WebSocket | null;
    private heartbeatInterval: number;
    private heartbeatIntervalId: any;
    private missedPongCount: number;
    private maxMissedPongs: number;
    private deviceId: string;

    constructor(props: WebSocketServiceOptions) {
        super(); // Initialize the EventEmitter

        this.accessToken = props.accessToken;
        this.env = props.environment || 'dev';
        this.socket = null;
        this.heartbeatInterval = props.heartbeatInterval; // Ping every 10 seconds for testing purposes
        this.heartbeatIntervalId = null;
        this.missedPongCount = 0;
        this.maxMissedPongs = 2; // Reconnect after 2 missed pongs
        this.deviceId = props.deviceId;

        WebSocketService.instance = this;
    }

    static isActive() {
        return WebSocketService.instance !== null;
    }
    public getSocketStatus(): WebSocket | null {
        return this.socket;
    }


    static getInstance(options?: WebSocketServiceOptions): WebSocketService | null {
        if (!WebSocketService.instance) {
            if (!options) {
                return null
            }
            WebSocketService.instance = new WebSocketService(options);
        }
        return WebSocketService.instance;
    }

    connect() {
        this.close(); // Close any existing connections before creating a new one
        this.socket = null;
        this.missedPongCount = 0;
        this.socket = new WebSocket(`${WS_BASE_URL}?token=${this.accessToken}&device_id=${this.deviceId}`);

        this.socket.onopen = () => {
            // console.trace('WebSocket connection established');
            this.emit('connected');
            this.startHeartbeatCheck();
        };

        this.socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event.reason, 'Code:', event.code);
            this.emit('disconnected', event);
            // this.reconnect(); // Attempt to reconnect on close.  Uses exponential backoff
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.emit('error', error);
        };

        this.socket.onmessage = (message) => {
            // console.log('Received message:', message.data);
            try {
                const parsedData = JSON.parse(message.data);
                // console.log('Parsed message:', parsedData);
                if (parsedData.message === 'pong') {
                    console.log('PONG');
                    this.missedPongCount = 0;
                    this.emit('pong');
                } else {
                    // console.log('New message received:', parsedData);
                    this.emit('messageReceived', parsedData);
                }
            } catch (err) {
                console.error('Failed to parse message:', err);
            }
        };
    }

    // Start the heartbeat ping/pong mechanism to check if the server is responsive
    startHeartbeatCheck() {
        if (this.heartbeatIntervalId) {
            clearInterval(this.heartbeatIntervalId); // Clear any previous interval
        }
        console.log("restarting heartbeat with interval", this.heartbeatInterval)
        this.heartbeatIntervalId = setInterval(() => {
            // console.log("PING", this.accessToken)
            console.log("PING")
            this.sendMessage({
                'message': 'ping',
            });

            // Check if pongs have been missed
            this.missedPongCount++;
            if (this.missedPongCount >= this.maxMissedPongs) {
                console.log('Missed pongs. Closing WebSocket...', this.missedPongCount, this.maxMissedPongs);
                this.reconnect();
            }
        }, this.heartbeatInterval);
    }

    // Clear the heartbeat interval when not needed
    clearHeartbeatCheck() {
        if (this.heartbeatIntervalId) {
            clearInterval(this.heartbeatIntervalId);
            this.heartbeatIntervalId = null;
            console.log('Stopped heartbeat checks.');
            // show stack trace here
            // console.trace("Trace for stopping heartbeat checks");
        }
    }

    // Perform WebSocket reconnection using an exponential backoff strategy
    reconnect() {
        this.missedPongCount++;
        const maxDelay = 60000; // Max delay of 30 seconds
        const delay = Math.min(2500 * (2 ** this.missedPongCount), maxDelay); // Exponential backoff

        console.log("HERE HERE HERE HERE")
        this.clearHeartbeatCheck(); // Ensure heartbeat check is cleared before reconnecting

        if (delay > 30000) {
            console.warn(`Reconnection delay exceeds ${delay}ms. Skipping reconnection.`);
            return;
        }
        setTimeout(() => {
            console.log(`Reconnecting WebSocket after ${delay}ms...`);
            this.connect();
        }, delay);
    }


    // Send a message through the WebSocket if it’s open
    sendMessage(data: object) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const payload = {
                ...data,
                access_token: this.accessToken,
                device_id: this.deviceId,
            };
            // console.log('Sending message:', payload);
            this.socket.send(JSON.stringify(payload));
        } else {
            console.error('WebSocket is not open. Cannot send message.');
        }
    }

    updateAccessToken(newAccessToken: string) {
        this.accessToken = newAccessToken;
        console.log("Access token updated in WebSocketService:", this.accessToken);
    }

    // Close the WebSocket connection and clear intervals
    close() {
        // console.trace("Trace for myFunction");
        // console.log(new Error().stack);
        console.log("WS: Closing WebSocket connection");
        if (this.socket) {
            // console.log("calling this.socket.close()");
            this.socket.close(1000);
        }
        this.clearHeartbeatCheck(); // Stop heartbeat checks when closing
        WebSocketService.instance = null; // Clear the instance
        this.socket = null; // Clear the socket
        console.log("this.socket is now", this.socket);
    }
}
