<template>
  <v-bottom-navigation color="duelly_black" v-if="viewNavBar()" app class="bottom-navigation">
    <div class="nav-button" >
      <v-icon color="" size="32" @click="navigateTo('/')">mdi-home</v-icon>
    </div>

    <!-- Spacer -->
    <div class="nav-button" > </div>

    <div class="nav-button" >
      <img
        :src="uploadIcon"
        alt="Upload Icon"
        @click="$router.push({ path: '/create-challenge', query: { type: 'challenge' } })"
        :style="{ width: '32px', height: '32px', cursor: 'pointer' }"
      />
      <!-- <v-icon color="" size="32" @click="$router.push({path: '/create-challenge', query: {type: 'challenge'}})">mdi-plus</v-icon> -->
    </div>

    <!-- Spacer -->
    <div class="nav-button" ></div>

    <div class="nav-button" v-if="userProfile.username" >
      <ProfilePhoto 
        class="mr-2"
        :username="userProfile.username" 
        :profile_photo="userProfile.profile_photo"
        size="small"
        @click="navigateTo('/user-management')"
      />
    </div>
    <div v-else class="nav-button" >
      <a @click="$router.push('/login')">Login</a>
    </div>
  </v-bottom-navigation>
</template>

  
  <script setup>
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/store/authStore';
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import uploadIcon from '@/assets/icons/upload_icon_classy_large.png';
  import ProfilePhoto from '@/components/ProfilePhoto.vue';



  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();
  const userProfile = ref(authStore.getUserProfile());

const viewNavBar = () => {    
    userProfile.value = authStore.getUserProfile();
    if (route.path === '/login') {
        return false;
    } else {
        return true;
    }
};
  
  function navigateTo(path) {
    router.push(path);
  }
  </script>


<style scoped>
.bottom-navigation {
  width: 100%;
  /* display: flex; */
  justify-content: space-between; 
  align-items: center; /* Center icons vertically */
  border-top: 2px solid rgb(50, 50, 50);
}

.nav-button {
  flex: 1; /* Allow buttons to grow equally */
  display: flex;
  justify-content: center; /* Center icon within button */
  align-items: center; /* Center icon vertically */
}
.nav-button:active {
  opacity: 0.5; 
}
</style>