/**
 * cacheWithTTL
 * 
 * A utility function to manage data caching with the browser's Cache API,
 * supporting a time-to-live (TTL) mechanism to automatically expire stale cache entries.
 * 
 * The function takes an array of cache keys, checks the browser's cache for each key,
 * and if the cached data is found and still valid (not expired), it is returned. For any
 * keys that are missing or have expired cache entries, the provided `fetchFn` is used to
 * fetch fresh data from the server or another source, and the new data is then cached.
 * 
 * The purpose of this function is to improve performance by caching results and reducing
 * unnecessary API calls while ensuring that data remains fresh through TTL-based expiration.
 * 
 * @template T - The type of the data being cached.
 * 
 * @param {string[]} keys - The cache keys to check in the cache storage. Each key uniquely identifies a data item.
 * @param {(uncachedKeys: string[]) => Promise<T[]>} fetchFn - A function to fetch data for uncached or expired keys.
 *        It receives an array of keys that need fetching and should return a `Promise` resolving to an array of
 *        data items of type `T`.
 * @param {number} [ttlSeconds=3600] - The time-to-live (TTL) for cache entries, in seconds. The default is 3600 seconds (1 hour).
 * 
 * @returns {Promise<T[]>} - A `Promise` that resolves to an array of data items of type `T`, combining both cached and
 *          newly fetched data based on the provided keys.
 * 
 * @example
 * // Fetch user profiles with caching
 * const userKeys = ['user-123', 'user-456', 'user-789'];
 * 
 * const profiles = await cacheWithTTL<UserProfile>(
 *     userKeys,
 *     async (uncachedKeys) => {
 *         const user_ids = uncachedKeys.map(key => key.replace('user-', ''));
 *         const response = await api.getPublicProfiles(user_ids);
 *         return response.data.profiles.map(profile => new UserProfile(profile));
 *     },
 *     3600 // TTL in seconds
 * );
 * 
 * // The `profiles` array will contain all profiles corresponding to `userKeys`,
 * // either from the cache or freshly fetched if not found or expired.
 */

export async function cacheWithTTL<T>(
    keys: string[],
    fetchFn: (uncachedKeys: string[]) => Promise<T[]>,
    ttlSeconds = 3600
): Promise<T[]> {
    const cache = await caches.open('duelly-api-cache');

    const cachedResults: T[] = [];
    const uncachedKeys: string[] = [];

    for (const key of keys) {
        const cachedResponse = await cache.match(key);
        const currentTime = Date.now();

        if (cachedResponse) {
            const cachedData = await cachedResponse.json();
            if (currentTime < cachedData.expiry) {
                cachedResults.push(cachedData.data as T);
            } else {
                // Cache expired
                uncachedKeys.push(key);
                await cache.delete(key);
            }
        } else {
            // Not found in cache
            uncachedKeys.push(key);
        }
    }

    // Fetch uncached data if any
    if (uncachedKeys.length > 0) {
        const fetchedData = await fetchFn(uncachedKeys);
        const expiry = Date.now() + ttlSeconds * 1000; // TTL in ms

        // Store fetched data in cache
        for (const [index, key] of uncachedKeys.entries()) {
            await cache.put(key, new Response(JSON.stringify({ data: fetchedData[index], expiry })));
        }

        return [...cachedResults, ...fetchedData];
    }

    return cachedResults;
}
