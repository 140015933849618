<template>
  <div class="view-wrapper center">
    <div class="view-content center">
      <TopicSlider class="duelly-regular mt-5" @topic-selected="fetchChallengesByTopic"/>
    </div>
  </div>

  <div class="view-wrapper mt-1">

    <content-loader v-if="loading" viewBox="0 0 250 1100" primaryColor="#1A1A1A" secondaryColor="#2E2E2E">
      <circle cx="20" cy="20" r="10" />
      <rect x="35" y="10" rx="5" ry="15" width="84%" height="20" />
      <rect x="5" y="35" rx="5" ry="5" width="96%" height="300" />
      <circle cx="20" cy="355" r="10" />
      <rect x="35" y="345" rx="5" ry="15" width="84%" height="20" />
      <rect x="5" y="370" rx="5" ry="5" width="96%" height="300" />
    </content-loader>

    <InfiniteScrollList 
      :fetchItems="fetchChallenges" 
      :resetKey="topic"
    >
      <template #default="{ item }">
        <div class="scroll-item">
          <ScrollItemHeader 
            :challenge="item"
            :route_path="{ path: '/challenge-details', query: { challenge_id: item.challenge_id, challenge_user_id: item.user_id } }"
          />
          <VideoPlayer2 
            :title="item.title" 
            :videoSrc="item.video"  
            :previewImage="item.thumbnail"
            @play="submitView(item.challenge_id)"
            class="video-player"
          />
          <ScrollItemFooter :challenge="item"/>
        </div>
      </template>
    </InfiniteScrollList>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted} from 'vue';
import TopicSlider from '../components/TopicSlider.vue';
import VideoPlayer2 from '@/components/VideoPlayer2.vue';
import ScrollItemHeader from '@/components/ScrollItemHeader.vue';
import ScrollItemFooter from '@/components/ScrollItemFooter.vue';
import InfiniteScrollList from '@/components/InfiniteScrollList.vue';
import { useChallengeStore } from '@/store/challengeStore';
import api from '@/services/api';
import { ContentLoader } from 'vue-content-loader'
import { useAuthStore } from '@/store/authStore';
import { useWebSocketStore } from '@/store/webSocketStore';
import { useNoticeStore } from '@/store/noticeStore';
import { useLikeStore } from '@/store/likeStore';
import ChallengeService from '@/services/ChallengeService';

const topic = ref("Trending");
const loading = ref(true);
const challengeStore = useChallengeStore();
const authStore = useAuthStore();
const webSocketStore = useWebSocketStore();
const noticeStore = useNoticeStore();
const likeStore = useLikeStore();

authStore.showTokenState()

const fetchChallenges = async (lastEvaluatedKey) => {
  console.log("lastEvaluatedKey", lastEvaluatedKey)

  let response;
  if (topic.value === "Trending") {
    response = await ChallengeService.getTrendingChallenges(10, lastEvaluatedKey);
  } else if (topic.value === "Recents") {
    response = await ChallengeService.getRecentChallenges(10, lastEvaluatedKey);
  } else {
    response = {
      challenges: [],
      last_evaluated_key: null,
      user_likes: [],
    };
    // response = await ChallengeService.getChallengesByTopic(topic.value, 10, lastEvaluatedKey);
  }

  // Process response
  if (response.user_likes) {
    likeStore.addUserLikes(response.user_likes);
  }

  if (response.challenges) {
    challengeStore.addChallenges(response.challenges);
  }

  loading.value = false;

  return {
    items: response.challenges,
    lastEvaluatedKey: response.last_evaluated_key,
  };
};

const submitView = async (challenge_id: string) => {
    await ChallengeService.addView(challenge_id);
}

// Fetch challenges when the topic changes
const fetchChallengesByTopic = (newTopic: string) => {
  topic.value = newTopic;
  return newTopic;
};

onMounted(async () => {
  authStore.ensureAuthTokens();
  const accessToken = authStore.getAccessToken();
  if (!accessToken) {
    return;
  }
  if (!webSocketStore.connected) {
    webSocketStore.initialize(accessToken);
  }

  // Fetch users current unread count
  api.getUnreadNotificationCount(accessToken).then((response) => {
    noticeStore.unreadCount = response.data.count
  });

  api.getNotifications(accessToken, 1000, null).then((response) => {
    noticeStore.notifications = response.data.notifications;
  });

});




</script>

<style scoped>
.video-player {
  width: 100%;
  object-fit: contain;
  max-width: 700px;         
  height: auto; 
  border-radius: 8px;
  background-color: black;
}


</style>
