import { App, DirectiveBinding } from 'vue';

const TouchEvents = {
    install(app: App) {
        // Register swipe directive
        app.directive('duelly-swipe', {
            beforeMount(el: HTMLElement, binding: DirectiveBinding<(direction: string) => void>) {
                let touchStartX = 0;
                let touchStartY = 0;
                let touchEndX = 0;
                let touchEndY = 0;

                function handleSwipe() {
                    const deltaX = touchEndX - touchStartX;
                    const deltaY = touchEndY - touchStartY;

                    if (Math.abs(deltaX) > Math.abs(deltaY)) {
                        deltaX > 30 ? binding.value('right') : binding.value('left');
                    } else {
                        deltaY > 30 ? binding.value('down') : binding.value('up');
                    }
                }

                el.addEventListener('touchstart', (e) => {
                    touchStartX = e.changedTouches[0].screenX;
                    touchStartY = e.changedTouches[0].screenY;
                });

                el.addEventListener('touchend', (e) => {
                    touchEndX = e.changedTouches[0].screenX;
                    touchEndY = e.changedTouches[0].screenY;
                    handleSwipe();
                });
            },
        });

        // Register double-click directive
        app.directive('duelly-double-click', {
            beforeMount(el: HTMLElement, binding: DirectiveBinding<() => void>) {
                let lastClickTime = 0;

                el.addEventListener('click', () => {
                    const now = Date.now();
                    if (now - lastClickTime < 300) {
                        binding.value(); // Trigger callback
                    }
                    lastClickTime = now;
                });
            },
        });
    },
};

export default TouchEvents;
