// store/challengeStore.ts
import { defineStore } from 'pinia';
import { Challenge } from '@/models/Challenge';
import api from '@/services/api';


interface ChallengeStoreState {
    challenges: { [PK: string]: Challenge };
}

export const useChallengeStore = defineStore('challengeStore', {
    state: (): ChallengeStoreState => ({
        challenges: {},
    }),

    actions: {
        // Add multiple challenges to the store by PK
        addChallenges(challenges: Challenge[]) {
            challenges.forEach((challenge) => {
                if (challenge.PK) {
                    this.challenges[challenge.PK] = challenge;
                }
            });
        },
        // Get a single challenge by its PK
        getChallenge(PK: string): Challenge | undefined {
            return this.challenges[PK];
        },

        // Remove a challenge by its PK
        removeChallenge(PK: string) {
            if (this.challenges[PK]) {
                delete this.challenges[PK];
            }
        },

        // Clear the entire challenge cache
        clearCache() {
            this.challenges = {};
        },

        // Update specific properties of a challenge (e.g., like or comment counts)
        updateChallenge(PK: string, updates: Partial<Challenge>) {
            if (this.challenges[PK]) {
                this.challenges[PK] = { ...this.challenges[PK], ...updates };
            }
        },
    },
});
