<script setup>
import { ref, onMounted } from 'vue';
import api from '@/services/api';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/authStore';
import { useChallengeStore } from '@/store/challengeStore';
import { useLikeStore } from '@/store/likeStore';
import { useResponseStore } from '@/store/responseStore';
import duelly_logo from '@/assets/imgs/duelly_logo_glow_more.png';
import duelly_logo_transparent from '@/assets/imgs/duelly_logo_transparent.png';
import InfoBox from '@/components/InfoBox.vue';


const router = useRouter();
const auth_store = useAuthStore();
const challenge_store = useChallengeStore();
challenge_store.clearCache();

const like_store = useLikeStore();
like_store.clearLikes();

const response_store = useResponseStore();
response_store.clear();

const info_message = ref('');
const loading = ref(undefined);
const showPassword = ref(false);

const errorMessage = ref('');
const isLogin = ref(true);
const formData = ref({
    email: '',
    password: '',
});
const googleButtonInitialized = ref(false);

const toggleView = () => {
    isLogin.value = !isLogin.value;
};

// info_message.value = 'Please login to continue.';

async function handleLogin() {
    loading.value = "#FF5C39"
    const { contact, password } = formData.value;
    let loginResponse;
    try {
        loginResponse = await api.login(contact, password)
    } catch(err) {
        console.error('Login error:', err);
        errorMessage.value = err.response.data.error || 'An error occurred during login. Please try again.';
        loading.value = undefined;
        return;
    }
    const userProfile = loginResponse.data.UserProfile;
    const authTokens = loginResponse.data.Auth_Tokens;

    auth_store.setAuthTokens(authTokens);
    auth_store.setProfile(userProfile); 
    router.push('/');
}

const handleSignup = () => {
    loading.value = "#FF5C39"

    const { contact, password } = formData.value;

    let email = null;
    let phone_number = null;

    // Basic Email Regex Check
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[0-9\s-]+$/;
    if (emailPattern.test(contact)) {
        email = contact;
    }else if (phonePattern.test(contact)) {
        phone_number = contact;
    }

    api.signUp( password, email, phone_number)
        .then((res) => {
            // Store the password in Vuex securely
            localStorage.setItem('password', password);
            console.log("password from localstorage", localStorage.getItem('password'));
            // store.dispatch('updatePassword', password);
            router.push({
                name: 'confirm-login',
                query: { user_id: res.data.user_id, contact: contact }  // Pass the email as a query parameter if needed
            });
        })
        .catch((err) => {
            console.error('Login error:', err);
            errorMessage.value = err.response?.data?.error || 'An error occurred during login. Please try again.';
        });
    // Call your registration service here
};

const handleGoogleLogin = (response) => {
    const idToken = response.credential;

    info_message.value = 'Hang tight, we are logging you in...';

    // Call the federatedLogin function with the provider 'google' and the ID token
    api.federatedLogin('google', idToken)
        .then((res) => {
            const userProfile = res.data.UserProfile;
            const authTokens = res.data.Auth_Tokens;

            auth_store.setAuthTokens(authTokens);
            auth_store.setProfile(userProfile);
            
            // Navigate to the home page
            router.push('/');
        })
        .catch((err) => {
            console.error('Federated login error:', err);
        });
};

const initializeGoogleSignIn = () => {
    // Set the global handler after ensuring the script is loaded
    window.handleGoogleLogin = handleGoogleLogin;

    if (!googleButtonInitialized.value) {
        // Initialize Google Sign-In
        window.google.accounts.id.initialize({
            client_id: '120650553600-s6e7dmkluio2mjfkl8f7sol7jtbqniea.apps.googleusercontent.com',
            callback: window.handleGoogleLogin, // Reference callback directly
        });

        window.google.accounts.id.renderButton(
            document.querySelector('.g_id_signin'),
            { theme: 'outline', size: 'large' }  // Customize button here
        );

        googleButtonInitialized.value = true; // Mark as initialized
    }
};

onMounted(() => {
    const intervalId = setInterval(() => {
        if (typeof window.google !== 'undefined') {
            initializeGoogleSignIn();
            clearInterval(intervalId);  // Stop checking once initialized
        } else {
            console.error('Google Sign-In script not loaded yet. Retrying...');
        }
    }, 250);  // Check every 500ms
});
    
</script>

<template>
    <v-container class="login">
        <InfoBox :infoMessage="info_message" />
    
      <v-row justify="center">
        <v-col cols="12" md="6">

        <div id="logo" class="center"></div>
          
          <v-row class="" justify="center">
            <v-switch
              v-model="isLogin"
              :label="isLogin ? 'Switch to Sign Up' : 'Switch to Login'"
              inset
              color="primary"
              class=""
            ></v-switch>
          </v-row>
  
          <v-card class="pa-4">
            <v-card-title class="text-center">{{ isLogin ? 'Login' : 'Sign Up' }}</v-card-title>
            
            <v-form @submit.prevent="isLogin ? handleLogin() : handleSignup()">
                <v-text-field
                    v-model="formData.contact"
                    label="Email or 1-###-###-####"
                    type="email"
                    :loading=loading
                    required
                    class="custom-label"
                ></v-text-field>
                <v-text-field
                    v-model="formData.password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    :loading=loading
                    required
                    append-icon="mdi-eye"
                    @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-alert
                    v-if="errorMessage"
                    title=""
                    :text="errorMessage"
                    type="error"
                    variant="tonal"
                    style="border-radius: 8px; padding: 16px; color: #D32F2F;"
                ></v-alert>
    
                <v-btn
                    color="primary"
                    type="submit"
                    class="mt-4"
                    block
                >
                    {{ isLogin ? 'Login' : 'Sign Up' }}
                </v-btn>
            </v-form>
  
            <div class="divider my-4">OR</div>
  
          <div id="g_id_onload"
               data-client_id="120650553600-s6e7dmkluio2mjfkl8f7sol7jtbqniea.apps.googleusercontent.com"
               data-callback="handleGoogleLogin"
               data-auto_prompt="false">
          </div>
          <div class="g_id_signin" data-type="standard"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<style scoped>
#g_id_onload, .g_id_signin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}

.login {
    margin-top: 50px;
}
  
.divider {
    position: relative;
    text-align: center;
    font-size: 16px;
    color: #aaa;
}
  
.divider:before,
.divider:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background: #ddd;
  }
  
.divider:before {
    left: 0;
}

.divider:after {
    right: 0;
}
.text-wrap {
    white-space: normal;
    text-align: center;
    width: 100%;
}

.wrap-text {
    display: inline-block;
    max-width: 100%;
    word-break: break-word;
}

.duelly_logo {
    border-radius: 12px;
}

/** Transition for the logo */
#logo {
  background-image: url('@/assets/imgs/duelly_logo_transparent.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px; /* Adjust based on your image dimensions */
  animation: logoTransition 5s ease-in-out forwards;
}

@keyframes logoTransition {
  0% {
    background-image: url('@/assets/imgs/duelly_logo_transparent.png');
  }
  100% {
    background-image: url('@/assets/imgs/duelly_logo_glow_more.png');
  }
}

</style>
  