<template>
    <div class="challenge-header">
        <ProfilePhoto 
          class="mr-2"
          :username="userProfile.username" 
          :profile_photo="userProfile.profile_photo" 
          @click="router.push({ path: '/public-profile', query: { user_id: userProfile.id } })"
        />

        <div class="challenge-details"  @click="handleClick">
            <h2 class="heading"> {{ challenge.title }}</h2>
            <span class="subheading duelly-fog subhead">By: {{  userProfile.username }}</span>
            <span class="subheading duelly-fog subhead">
              Status: 
              <span 
                class="status" 
                :class="{'text-success': challengeStatus() === 'Active'}"
              >
                {{ challengeStatus() }}
              </span>
            </span>

            <!-- <span class="subheading duelly-fog subhead">Status: <span class="status"></span>{{ challengeStatus() }}</span></span> -->
        </div>
        <span></span>
    </div>
</template>

<style scoped>
.challenge-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  gap: 20px;
}

.challenge-details {
  display: flex;
  flex-direction: column;
}
.subhead {
  font-size: 12px;
}
</style>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserProfileStore } from '@/store/userProfileStore';
import { useAuthStore } from '@/store/authStore';
import ProfilePhoto from '@/components/ProfilePhoto.vue';


const router = useRouter();
const auth_store =  useAuthStore();
const store = useUserProfileStore();


const props = defineProps({
  challenge: Object,
  route_path: Object,
});

const userProfile = ref({});

const challengeStatus = () => {
  if (props.challenge.status === 'published' ) {
    return 'Active';
  } else {
    return props.challenge.status
  }
};

const handleClick = () => {
  if (props.route_path) {
    router.push(props.route_path);
  }
};

const getProfile = async (user_id) => {
  userProfile.value = await store.getPublicProfile(user_id);
  return userProfile;
};

getProfile(props.challenge.user_id);

</script>