<template>
    <div class="video-container">    
        <!--
         NOTE:  We are using the 'controls' attribute to enable the video player's controls.
         On phones this shows a nice click control button, but in broswers it shows a default control bar.
         Do not try to add an onClick to the parent  (video-container) or to the video player (video-player)
        -->
        <video
          ref="videoPlayer"
          class="video-player"
          preload="none"
          controls 
          disablepictureinpicture
          playsinline
          webkit-playsinline
          x5-playsinline
          :poster="previewImage"
        ></video>   
    </div>
</template>

<style scoped>
.video-container {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 100;
}
video::-webkit-media-controls {
     visibility: hidden;
}

video::-webkit-media-controls-enclosure {
     visibility: visible;
}
.video-player {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: cover;
    border-radius: 1px;
    border-color:azure;
    background-color: black;
}
</style>
  
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Hls from 'hls.js';

// Define props using `defineProps`
const props = defineProps({
  videoSrc: {
    type: String,
    required: true,
  },
  previewImage: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['play']); // Define the 'play' event

// Reactive references for video player and playing state
const videoPlayer = ref(null);
const playing = ref(false);
const isVideoVisible = ref(false);
let hls; // HLS instance
let observer; // Intersection Observer instance

// onMounted lifecycle hook
onMounted(() => {
  const video = videoPlayer.value;

  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        initializeVideo();  // Initialize video loading only when visible
        observer.unobserve(video);  // Stop observing once loaded
      }
    });
  }, { 
    // root: document.querySelector('.infinite-scroll-container'),
    threshold: 0.50
  }); // Adjust threshold if needed

  observer.observe(video);
});

function initializeVideo() {
  const video = videoPlayer.value;

  // Add play event to start HLS loading on play
  video.addEventListener('play', () => {
    emit('play');
    hls.startLoad();  // Start loading segments only when video starts playing
  });

  // Initialize HLS.js if supported
  if (Hls.isSupported()) {
    hls = new Hls({ autoStartLoad: true, maxBufferLength: 10, maxBufferSize: 25 * 1024 * 1024 });
    hls.loadSource(props.videoSrc);  // Load the HLS video source
    hls.attachMedia(video);  // Attach HLS to the video element
    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      // console.log('manifest loaded, found ' + data.levels.length + ' quality level');
    });
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    // Fallback for Safari and native HLS support
    video.src = props.videoSrc;
  }
}

onBeforeUnmount(() => {
  // Cleanup HLS instance and Intersection Observer
  if (hls) {
    hls.destroy();
  }
  if (observer) {
    observer.disconnect();
  }
});
</script>

