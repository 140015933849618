// services/axiosInterceptor.ts
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from '@/store/authStore';
import { useWebSocketStore } from '@/store/webSocketStore';
import route from '@/router';
import { EventEmitter } from 'events';

// Extend Axios's InternalAxiosRequestConfig to include _retry
declare module 'axios' {
    export interface InternalAxiosRequestConfig {
        _retry?: boolean;
    }
}

// Initialize EventEmitter instance
const authEventEmitter = new EventEmitter();

// Shared variables
let isRefreshing = false;
let failedQueue: Array<{
    resolve: (token: string) => void;
    reject: (error: any) => void;
}> = [];

// Function to process the failed queue
const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token!);
        }
    });
    failedQueue = [];
};

// These routes require a user to be authenticated before accessing/interacting
const restrictedRoutes = ['/challenge/add-comment', '/challenge/like'];

// Function to set up interceptors
export const setupInterceptors = (client: AxiosInstance) => {
    client.interceptors.response.use(
        (response: AxiosResponse) => response,
        async (error: AxiosError) => {
            console.log("YOU ARE RIGH THERE IN THE INTERCEPTOR")
            const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

            if (!originalRequest) {
                return Promise.reject(error);
            }

            // Handle 401 Unauthorized and 403 Forbidden errors
            if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
                console.log('Handling 401 or 403 error', error.response?.data);
                const matchesRestrictedRoute = restrictedRoutes.some((route) => originalRequest.url?.includes(route));
                if (matchesRestrictedRoute) {
                    console.warn(`Unauthorized access to restricted route: ${originalRequest.url}`);
                    alert('You must log in to perform this action.');
                    await route.push({ name: 'login' });
                    return Promise.reject(error); // Do not retry for restricted routes
                }

                if (isRefreshing) {
                    console.log("Already refreshing tokens")
                    // If token refresh is already in progress, queue the request
                    return new Promise<AxiosResponse | null>((resolve, reject) => {
                        failedQueue.push({
                            resolve: (token: string) => {
                                if (originalRequest.headers) {
                                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                }
                                resolve(client(originalRequest));
                            },
                            reject: (err: any) => {
                                reject(err);
                            }
                        });
                    });
                }

                // Mark the request as retry
                originalRequest._retry = true;
                isRefreshing = true;

                const authStore = useAuthStore();
                const wsStore = useWebSocketStore();

                try {
                    const newAuthTokens = await authStore.refreshTokens();
                    if (newAuthTokens) {
                        // Update Authorization header for future requests
                        if (!originalRequest.headers) {
                            originalRequest.headers = {};
                        }
                        originalRequest.headers['Authorization'] = `Bearer ${newAuthTokens.AccessToken}`;
                        client.defaults.headers.common['Authorization'] = `Bearer ${newAuthTokens.AccessToken}`;

                        // Emit 'tokensRefreshed' event with new tokens
                        authEventEmitter.emit('tokensRefreshed', newAuthTokens.AccessToken);

                        processQueue(null, newAuthTokens.AccessToken);
                        console.log("returning client(originalRequest)")
                        return client(originalRequest);
                    }
                } catch (err) {
                    processQueue(err, null);

                    // Emit 'logout' event to handle logout operations elsewhere
                    authEventEmitter.emit('logout');

                    // Clear authentication and redirect to login
                    authStore.clearAuth();
                    wsStore.disconnect();
                    await route.push({ name: 'login' });
                    return Promise.reject(err);
                } finally {
                    isRefreshing = false;
                }
            }

            const propogate_404s = ['/user/login'];

            // Handle specific status codes
            if (error.response?.status === 404) {
                const shouldPropagate = propogate_404s.some((endpoint) => originalRequest?.url?.includes(endpoint));

                if (shouldPropagate) {
                    console.error(`Ignoring 404 for ${originalRequest.url}`);
                    return Promise.reject(error);
                }
                console.error(`Resource not found: 404 on ${originalRequest.url}`);
                return Promise.resolve(null); // prevent propagation of error
            }

            if (error.response?.status === 500) {
                console.error(`Server error: 500 on ${originalRequest.url}`);
                return Promise.resolve(null);
            }

            return Promise.reject(error);
        }
    );
};

// Export the EventEmitter for other services to listen to
export { authEventEmitter };
