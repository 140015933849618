// stores/userProfileStore.ts
import { defineStore } from 'pinia';
import { UserProfile } from '@/models/UserProfile';
import { cacheWithTTL } from '@/utils/cacheWithTTTL';
import api from '@/services/api';

interface UserProfileStoreState {
    profiles: { [userId: string]: UserProfile };
}

export const useUserProfileStore = defineStore('userProfile', {
    state: (): UserProfileStoreState => ({
        profiles: {},
    }),
    actions: {
        async getPublicProfile(userId: string): Promise<UserProfile | null> {
            // Check if the profile is already in the store
            if (this.profiles[userId]) {
                return this.profiles[userId];
            }

            // Use cacheWithTTL to fetch and cache the profile
            const cacheKeys = [`user-profile-${userId}`];
            const ttlSeconds = 3600; // 1 hour TTL

            const profilesData = await cacheWithTTL<UserProfile | null>(
                cacheKeys,
                async () => {
                    return [await UserProfile.getPublicProfile(userId)];
                },
                ttlSeconds
            );

            const profile = profilesData[0];

            if (profile) {
                // Store the profile in the Pinia store
                this.profiles[userId] = profile;
            }

            return profile;
        },

        async getPublicProfiles(userIds: string[], ttlSeconds = 3600): Promise<UserProfile[]> {
            const profilesToFetch: string[] = [];
            const profilesFetched: UserProfile[] = [];

            // Check which profiles are already in the store
            for (const userId of userIds) {
                const cachedProfile = this.profiles[userId];
                // Validate that the cached profile is an instance of UserProfile and has necessary properties
                if (cachedProfile instanceof UserProfile && cachedProfile.id) {
                    profilesFetched.push(cachedProfile);
                } else {
                    profilesToFetch.push(userId);
                }
            }

            // Fetch profiles that are not in the store
            if (profilesToFetch.length > 0) {
                const fetchedProfiles = await api.getPublicProfiles(profilesToFetch);
                for (const profile of fetchedProfiles) {
                    if (profile && profile.id) { // Ensure the fetched profile is valid
                        this.profiles[profile.id] = profile;
                        profilesFetched.push(profile);
                    } else {
                        console.error('Invalid profile fetched!!!!', profile);
                    }
                }
            }

            return profilesFetched;
        },
    },
});
