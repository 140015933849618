import { challengeServiceClient } from '@/services/api';
import { Challenge } from '@/models/Challenge';
import api from '@/services/api';
import axios from 'axios';
import { AxiosResponse } from 'axios';

class ChallengeService {
    static async getChallenge(challenge_id: string) {
        const resp = await challengeServiceClient.get('/challenge/get-challenge', {
            params: {
                challenge_id
            }
        });
        return resp.data.challenge;
    }

    static async getCountByUserId(user_id: string): Promise<number> {
        const resp = await challengeServiceClient.get('/challenge/user-challenges-count', {
            params: {
                user_id
            }
        });
        return resp.data.count;
    }

    static async getUserJudgedChallengesCount(user_id: string, token: string): Promise<number> {
        const resp = await challengeServiceClient.get('/challenge/user-judged-challenge-count', {
            params: {
                user_id,
            },
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return resp.data.count;
    }

    static getUserChallengesCount(user_id: string) {
        // Make the GET request using challengeServiceClient
        return challengeServiceClient.get('/challenge/user-challenges-count', {
            params: {
                user_id
            }
        });
    }

    static async getUserChallengeAuth(challenge_id: string, token: string): Promise<Challenge | null> {
        const resp = await challengeServiceClient.get('/challenge/user-challenge', {
            params: {
                challenge_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if (resp.data && resp.data.challenge) {
            return resp.data.challenge;
        }
        return null;
    }

    static getPrizePhotoSignedUrl(challenge_id: string, token: string) {
        return challengeServiceClient.get('/challenge/get-signed-url-for-prize-photo', {
            params: {
                challenge_id,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    static async updateChallenge(accessToken: string, challenge: Challenge): Promise<boolean> {
        try {
            const resp = await challengeServiceClient.post('/challenge/update', challenge, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // const resp = await api.updateChallenge(accessToken, this);
            if (resp.status === 200 && resp.data.challenge) {
                Object.assign(this, resp.data.challenge);
                return true;
            } else {
                console.error('Failed to update challenge:', resp.data.message);
                return false;
            }
        } catch (error) {
            console.error('Error updating challenge:', error);
            return false;
        }
    }

    static async getRecentChallenges(
        limit: number,
        last_evaluated_key: any = null,
        accessToken: string | null = null
    ): Promise<{ challenges: Challenge[], last_evaluated_key: any, user_likes: string[] }> {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const resp = await challengeServiceClient.get('/challenge/get-recent', {
            params: {
                limit,
                last_evaluated_key: serializedKey,
            },
            // headers: {
            //     Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            // }
        });
        if (!resp.data.challenges || resp.data.challenges.length === 0) {
            return { challenges: [], last_evaluated_key: null, user_likes: [] };
        }
        return {
            challenges: resp.data.challenges,
            last_evaluated_key: resp.data.last_evaluated_key || null,
            user_likes: resp.data.user_likes || [],
        };
    }

    static async getTrendingChallenges(limit: number, last_evaluated_key: any = null) {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const trendingResponse = await challengeServiceClient.get('/challenge/get-trending', {
            params: {
                limit,
                last_evaluated_key: serializedKey,
            }
        });
        return {
            challenges: trendingResponse.data.challenges,
            last_evaluated_key: trendingResponse.data.last_evaluated_key || null,
            user_likes: trendingResponse.data.user_likes || [],
        };
    }

    static async addView(challenge_id: string, parent_type = 'CHALLENGE') {
        try {
            const resp = await challengeServiceClient.post('/challenge/add-view', {
                parent_id: challenge_id,
                parent_type: parent_type
            });
            if (!resp) {
                console.error('Failed to add view to challenge');
                return false;
            }

            return resp.data.response;
        } catch (error) {
            console.error('Error adding view:', error);
            return false;
        }
    }

    static async getJudgesForChallenge(challenge_id: string, limit: number, last_evaluated_key: any = null) {
        // Serialize last_evaluated_key if it's not null
        const serializedKey = last_evaluated_key ? JSON.stringify(last_evaluated_key) : null;

        // Make the GET request using challengeServiceClient
        const response = await challengeServiceClient.get('/challenge/get-judges-for-challenge', {
            params: {
                challenge_id,
                limit,
                last_evaluated_key: serializedKey,
            }
        });
        console.log("getJudgesForChallenge response", response.data);
        return {
            judges: response.data.judges,
            user_profiles: response.data.profiles,
            last_evaluated_key: response.data.last_evaluated_key || null,
        };
    }
}

export default ChallengeService;
