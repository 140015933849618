import { defineStore } from 'pinia';
import WebSocketService, { WebSocketServiceOptions } from '@/services/WebSocketService';
import { useNoticeStore } from './noticeStore';
import { useAuthStore, authStoreEvents } from '@/store/authStore';



export const useWebSocketStore = defineStore('webSocketStore', {
    state: () => ({
        connected: false,
        deviceId: "",
        visibilityChangeListenerAdded: false,
    }),
    actions: {
        initialize(accessToken: string) {
            // console.log("websocket store initialize");
            if (!this.deviceId) {
                this.deviceId = this.generateDeviceId();
            }

            const options: WebSocketServiceOptions = {
                accessToken,
                environment: 'dev',
                heartbeatInterval: 10000,
                deviceId: this.deviceId,
            };

            const wsService = WebSocketService.getInstance(options);
            if (!wsService) {
                console.error('WebSocketService initialization failed.');
                return;
            }

            // Listen for token refresh events and update WebSocketService
            authStoreEvents.on('tokenRefreshed', (newAccessToken) => {
                wsService.updateAccessToken(newAccessToken);
                console.log("WebSocketService accessToken updated on refresh.");
                if (!this.connected) {
                    wsService.connect();
                }
            });

            if (!this.visibilityChangeListenerAdded) {
                this.visibilityChangeListenerAdded = true;

                document.addEventListener('visibilitychange', async () => {
                    const authStore = useAuthStore();
                    if (document.hidden) {
                        console.log("Page hidden - closing WebSocket connection.");
                        wsService.close();
                        console.log("socket status after close", wsService.getSocketStatus());
                        if (authStore.tokenRefreshTimer !== null) {
                            clearInterval(authStore.tokenRefreshTimer);
                            authStore.tokenRefreshTimer = null;  // Set to null after clearing
                        }
                    } else {
                        console.log("Page Visible - renablling WebSocket connection.");
                        // Check that authtokens are still valid
                        if (authStore.isAccessTokenExpired()) {
                            console.log("Token expired - refreshing tokens.");
                            await authStore.refreshTokens();
                        }
                        const accessToken = authStore.getAccessToken() as string
                        wsService.updateAccessToken(accessToken);
                        wsService.connect();
                        this.setupWebSocketListeners(wsService);
                        authStore.setAuthTokens(authStore.authTokens); // Restart token refresh timer
                    }
                });
            }
            // Set up WebSocket event listeners initially
            this.setupWebSocketListeners(wsService);
            wsService.connect();
            // console.log("websocket store conected");
        },
        setupWebSocketListeners(wsService: WebSocketService) {
            wsService.on('connected', this.onConnected);
            wsService.on('disconnected', this.onDisconnected);
            wsService.on('messageReceived', this.onMessageReceived);
            wsService.on('error', this.onError);
            wsService.on('pong', this.onPong);
        },

        onConnected() {
            this.connected = true;
            // console.log('Store: WebSocket connected');
        },
        onDisconnected(event: CloseEvent) {
            this.connected = false;
            console.log('Store: WebSocket disconnected', event.reason, 'Code:', event.code);
        },
        onMessageReceived(data: any) {
            console.log('Store: Received message:', data);
            if (typeof data.unread_count === 'number') {
                const noticeStore = useNoticeStore();
                noticeStore.unreadCount = data.unread_count;
            } else {
                console.log("Unhandled message type:", data);
            }
        },
        onError(error: any) {
            console.error('Store: WebSocket encountered an error:', error);
        },
        onPong() {
            // console.log('Store: Received pong');
            // Optionally update state or perform actions on pong
        },
        disconnect() {
            const wsService = WebSocketService.getInstance();
            wsService?.close();
            this.connected = false;
        },
        generateDeviceId(): string {
            return (crypto as any).randomUUID();
        },
    },
});
