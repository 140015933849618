<!-- src/components/ProfilePhoto.vue -->
<template>
    <div>
      <img
        v-if="hasProfilePhoto"
        :src="profile_photo"
        :alt="`Profile photo of ${username}`"
        :class="`${size}-profile-photo`"
      />
      <div
        v-else
        :class="`${size}-profile-photo placeholder`"
        :style="{ backgroundColor: generatedColor }"
        role="img"
        :aria-label="`Profile photo placeholder for ${username}`"
      >
        <span class="initials">{{ initials }}</span>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  import { defineProps } from 'vue';
  
  // Define props
  const props = defineProps({
    username: {
      type: String,
      required: true,
    },
    profile_photo: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
    },
  });
  
  // Computed property to check if profile_photo exists
  const hasProfilePhoto = computed(() => !!props.profile_photo);
  
  // Computed property to generate color based on username
  const generatedColor = computed(() => stringToColor(props.username));
  
  // Compute initials from username
  const initials = computed(() => {
    if (!props.username) return '';
    const names = props.username.trim().split(' ');
    if (names.length === 0) return '';
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
  });
  
  /**
   * Converts a string to a consistent hexadecimal color code.
   * @param {string} str - The input string (e.g., username).
   * @returns {string} - A hexadecimal color code.
   */
  function stringToColor(str) {
    if (!str) return '#000000'; // Default to black if no string provided

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += (`00${value.toString(16)}`).slice(-2);
    }
    return color;
  }
  </script>
  
  <style scoped>
div {
  display: block;
  display: flex;
}

/* Placeholder styling */
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
}


/* Initials styling */
.initials {
  /* Optional: Customize the initials appearance */
}
  </style>
  