import { defineStore } from 'pinia';

interface LikeStoreState {
    likeIds: string[];  // Store as an array
}

export const useLikeStore = defineStore('likeStore', {
    state: (): LikeStoreState => ({
        likeIds: [],
    }),
    persist: true,

    actions: {
        addUserLikes(likeIds: string[]) {
            const uniqueIds = new Set([...this.likeIds, ...likeIds]);
            this.likeIds = Array.from(uniqueIds);  // Replace the array for reactivity
        },

        isLiked(id: string): boolean {
            return this.likeIds.includes(id);
        },

        clearLikes() {
            this.likeIds = [];
        },

        removeLike(id: string) {
            this.likeIds = this.likeIds.filter((likeId) => likeId !== id);
        },
    },
});
