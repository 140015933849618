<template>
    <v-app-bar  v-if="viewNavBar()" app hide-on-scroll>
      <v-toolbar class="topnav" color="duelly_black">
        <vbtn>
        <v-icon
          v-if="showBackArrow"
          size="32"
          class="mr-3"
          @click="goBack"
        >
          mdi-arrow-left
        </v-icon>
      </vbtn>
        <v-img
          src="@/assets/duelly_top_nav_log.svg"
          alt=""
          max-height="50"
          max-width="150"
          class="mr-3"
          @click="$router.push('/')"
        ></v-img>

        <v-spacer></v-spacer>

        <v-badge
          v-if="unreadNotifications > 0"
          class="custom-badge"
          color="#FF5C45"
          :content="unreadNotifications"
          overlap
          offset-x="32"
          offset-y="6"
        >
          <v-icon 
            color=""
            size=32
            class="mr-8"
            @click="$router.push('/notifications')"
          >mdi-bell</v-icon>
        </v-badge>
        <v-icon
          v-else
          size="32"
          color=""
          class="mr-8"
          @click="$router.push('/notifications')"
        >
          mdi-bell
        </v-icon>
        <!-- <v-icon color="" size=32 class="mr-4">mdi-magnify</v-icon> -->
      </v-toolbar>
    </v-app-bar>
</template>
  
<script setup>
import { computed } from 'vue';
import { useNoticeStore } from '@/store/noticeStore';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

const noticeStore = useNoticeStore();
const unreadNotifications = computed(() => noticeStore.unreadCount);
const showBackArrow = computed(() => route.path !== '/');

const viewNavBar = () => {
    return route.path !== '/login';
};

console.log("window.history.length:", window.history.length);


const goBack = () => {
  if (window.history.length > 2) {
    router.back();
  } else {
    // No meaningful history; redirect to home
    router.push('/');
  }
};
</script>
  
<style scoped>
.topnav {
  border-bottom: 2px solid rgb(50, 50, 50);
}
</style>
  