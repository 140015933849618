/* eslint-disable no-console */

import { register } from 'register-service-worker'

function showUpdateNotification() {
  // Create a banner element
  const banner = document.createElement('div');
  banner.id = 'update-banner';
  banner.style.position = 'fixed';
  banner.style.bottom = '0';
  banner.style.left = '0';
  banner.style.width = '100%';
  banner.style.backgroundColor = '#fff3cd';
  banner.style.color = '#856404';

  // banner.style.backgroundColor = '#FF5C39'; // duelly primary color
  // banner.style.color = '#00C1DE'; // duelly secondary color
  banner.style.padding = '15px';
  banner.style.textAlign = 'center';
  banner.style.boxShadow = '0 -2px 5px rgba(0,0,0,0.3)';
  banner.style.zIndex = '9999';

  // Set the banner's inner HTML
  banner.innerHTML = `
    <span>New version available.</span>
    <button id="refresh-button" style="margin-left: 15px; padding: 5px 10px;">Update</button>
  `;

  // Append the banner to the body
  document.body.appendChild(banner);

  // Add click event listener to the refresh button
  const refreshButton = document.getElementById('refresh-button');
  if (refreshButton) {
    refreshButton.addEventListener('click', () => {
      window.location.reload();
    });
  }
}


if (process.env.NODE_ENV === 'production') {
  // console.log("Service worker is registered")
  register('/service-worker.js', {
    ready() {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )
    },
    registered() {
      // console.log('Service worker has been registered.')
    },
    cached() {
      // console.log('Content has been cached for offline use.')
    },
    updatefound() {
      // console.log('New content is downloading.')
    },
    updated() {
      showUpdateNotification();
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
