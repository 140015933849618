import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import TouchEvents from './lib/touch-events';
import './registerServiceWorker';


loadFonts()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App);
app.use(router)
  .use(store)
  .use(vuetify)
  .use(pinia)
  .use(TouchEvents)
  .mount('#app');

